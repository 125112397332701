import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper/modules";
import { CiCircleInfo } from "react-icons/ci";
import SingleUniversityCard from "./university/SingleUniversityCard";
import Header from "./student_registation/components/Header";

const UniversityByObject = () => {
  const { loginData } = useSelector((store) => store.Athentication);
  const [temploading, setTempLoading] = useState(true);
  const [selectedUniversity, setselectedUniversity] = useState("");

  const [reachUniversity, setreachUniversity] = useState([]);
  const [dreamUniversity, setdreamUniversity] = useState([]);
  const [safeUniversity, setsafeUniversity] = useState([]);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/university/university-search-by-objective`;
      const formdata = {
        educationlevel: loginData.degreeName,
        experienceStatus: loginData.workExperienceStatus,
        workExperience: loginData.workExperience,
        Extracurricular: loginData.extracurricular,
        volunterwork: loginData.volunterwork,
        education: loginData.education.GraduationOption,
      };
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const fetchUiversity = await axios.post(url, formdata, config);
      if (fetchUiversity.data.success === true) {
        const uniTotalDream = fetchUiversity.data.dreamUniversity;
        const uniTotalReach = fetchUiversity.data.reachUniversity;
        const uniTotalSafe = fetchUiversity.data.safeUniversity;
        setdreamUniversity(uniTotalDream);
        setreachUniversity(uniTotalReach);
        setsafeUniversity(uniTotalSafe);
        setTempLoading(false);
      }
    };
    fetchData();
  }, [loginData]);

  return (
    <>
      <div
        className="main_container"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="container_box">
          <Header
            title="By Objectives"
            description={`Here are the universities that align with your  skills, work experience, and employability need.`}
          />
        </div>
        <div className="container_box max_height_cont background_style">
          {temploading === true ? (
            <>
              <div
                style={{
                  width: "100%",
                  height: "80vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h6>Loading...</h6>
              </div>
            </>
          ) : (
            <>
              <div className="unidispconta">
                <div className="unidisBox1">
                  <div className="imagebox">
                    <img src="/assets/img/dream-new.svg" height="100" alt="" />
                  </div>
                  <div className="textbox">
                    <h2 style={{ color: "#997fe6" }}>
                      {dreamUniversity.length}{" "}
                      <CiCircleInfo
                        data-tooltip-id="my-tooltip-1"
                        style={{
                          fontSize: "2.6vh",
                          marginLeft: "3vh",
                          cursor: "pointer",
                        }}
                      />
                    </h2>
                    <h4 style={{ color: "#997fe6" }}>Dream</h4>
                  </div>
                </div>
                <div className="unidisBox2">
                  <div className="imagebox">
                    <img src="/assets/img/reach-new.svg" height="100" alt="" />
                  </div>
                  <div className="textbox">
                    <h2 style={{ color: "#ff8e37" }}>
                      {reachUniversity.length}{" "}
                      <CiCircleInfo
                        data-tooltip-id="my-tooltip-1"
                        style={{
                          fontSize: "2.6vh",
                          marginLeft: "3vh",
                          cursor: "pointer",
                        }}
                      />
                    </h2>
                    <h4 style={{ color: "#ff8e37" }}>Reach</h4>
                  </div>
                </div>
                <div className="unidisBox3">
                  <div className="imagebox">
                    <img src="/assets/img/safe-new.svg" height="100" alt="" />
                  </div>
                  <div className="textbox">
                    <h2 style={{ color: "#57d3db" }}>
                      {safeUniversity.length}{" "}
                      <CiCircleInfo
                        data-tooltip-id="my-tooltip-1"
                        style={{
                          fontSize: "2.6vh",
                          marginLeft: "3vh",
                          cursor: "pointer",
                        }}
                      />
                    </h2>
                    <h4 style={{ color: "#57d3db" }}>Safe</h4>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap justify-between mb-1">
                <div className="uniheader">
                  <img src="/assets/images/dream-new.svg" height="100" alt="" />
                  <h1>{dreamUniversity.length} Dream Universities</h1>
                </div>
              </div>

              <Swiper
                mousewheel={true}
                // pagination={{
                //   clickable: true,
                // }}
                navigation={false}
                modules={[Pagination]}
                keyboard={true}
                draggable={true}
                effect={"fade"}
                loop={true}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                breakpoints={{
                  360: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },
                  460: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },
                  720: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1080: {
                    slidesPerView: 4,
                    spaceBetween: 7,
                  },
                }}
                className="mySwiper"
              >
                {dreamUniversity &&
                  dreamUniversity.slice(0, 10).map((uiversity, index) => (
                    <SwiperSlide key={index}>
                      <>
                        <SingleUniversityCard
                          uiversity={uiversity}
                          index={index}
                        />
                      </>
                    </SwiperSlide>
                  ))}
              </Swiper>

              <div className="flex flex-wrap justify-between mb-1">
                <div className="uniheader">
                  <img src="/assets/images/reach-new.svg" height="100" alt="" />
                  <h1>{reachUniversity.length} Reach Universities</h1>
                </div>
              </div>
              <Swiper
                mousewheel={true}
                // pagination={{
                //   clickable: true,
                // }}
                navigation={false}
                modules={[Pagination]}
                keyboard={true}
                draggable={true}
                effect={"fade"}
                loop={true}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                breakpoints={{
                  360: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },
                  460: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },
                  720: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1080: {
                    slidesPerView: 4,
                    spaceBetween: 7,
                  },
                }}
                className="mySwiper"
              >
                {reachUniversity &&
                  reachUniversity.slice(0, 10).map((uiversity, index) => (
                    <SwiperSlide key={index}>
                      <>
                        <SingleUniversityCard
                          uiversity={uiversity}
                          index={index}
                        />
                      </>
                    </SwiperSlide>
                  ))}
              </Swiper>
              <div className="flex flex-wrap justify-between mb-1">
                <div className="uniheader">
                  <img src="/assets/images/safe-new.svg" height="100" alt="" />
                  <h1>{safeUniversity.length} Safe Universities</h1>
                </div>
              </div>
              <Swiper
                mousewheel={true}
                // pagination={{
                //   clickable: true,
                // }}
                navigation={false}
                modules={[Pagination]}
                keyboard={true}
                draggable={true}
                effect={"fade"}
                loop={true}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                breakpoints={{
                  360: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },
                  460: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },
                  720: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1080: {
                    slidesPerView: 4,
                    spaceBetween: 7,
                  },
                }}
                className="mySwiper"
              >
                {safeUniversity &&
                  safeUniversity.slice(0, 10).map((uiversity, index) => (
                    <SwiperSlide key={index}>
                      <>
                        <SingleUniversityCard
                          uiversity={uiversity}
                          index={index}
                        />
                      </>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </>
          )}
        </div>
      </div>
      <ReactTooltip
        id="my-tooltip-1"
        place="right"
        variant="info"
        content="Academic match is provided on a rating scale from 0-100 (100 is the best match) "
        style={{
          width: "50vh",
        }}
      />
    </>
  );
};

export default UniversityByObject;
